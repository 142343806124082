/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div",
    h2: "h2"
  }, _provideComponents(), props.components), {Callout} = _components;
  if (!Callout) _missingMdxReference("Callout", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Muchas veces creamos copias de seguridad de ", React.createElement(_components.a, {
    href: "/2016/06/04/como-instalar-una-raspberry-pi-sin-conectar-una-pantalla/"
  }, "nuestras imágenes de Raspberry Pi"), " o\nde otras placas similares. Estos dispositivos embebidos suelen tener una memoria\nprincipal dividida en varias particiones, por lo general tendrán una partición de\nboot para alojar el kernel y posiblemente también parte del bootloader y por otra\nparte tendrán el rootfs, es decir el sistema de archivos raíz.\nEl problema es que cuando creamos la copia de seguridad lo hacemos de la imagen\nen la tarjeta SD completa, con un comando parecido al siguiente:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> <span class=\"token function\">dd</span> <span class=\"token assign-left variable\">if</span><span class=\"token operator\">=</span>/dev/sdb <span class=\"token assign-left variable\">of</span><span class=\"token operator\">=</span>rpi-armhf.img</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Pero de esta forma no podemos coger esa imagen y montar directamente las dos particiones.\nTendríamos que montar cada partición utilizando la sección de la imagen correcta\nen dos directorios distintos, esto se puede hacer manualmente, pero por suerte\nes posible utilizar una herramienta que lo haga todo por nosotros, kpartx."), "\n", React.createElement(Callout, {
    variant: "warning"
  }, "Es el momento de hacer una copia de seguridad de tu imagen, recuerda que cualquier cosa podría salir mal."), "\n", React.createElement(_components.h2, null, "El tutorial"), "\n", React.createElement(_components.p, null, "Vamos a instalar kpartx y a utilizarlo para mapear cada partición a un archivo.\nYo utilizo Debian pero puedes buscar kpartx entre los paquetes de tu distribución\ny el resto del procedimiento es el mismo."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> <span class=\"token function\">apt</span> <span class=\"token function\">install</span> kpartx\n<span class=\"token function\">sudo</span> kpartx <span class=\"token parameter variable\">-v</span> <span class=\"token parameter variable\">-a</span> rpi-armhf.img</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Esto nos devuelve algo como lo siguiente, debemos quedarnos con esos loop* que\nson los archivos donde se han mapeado las particiones."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">add</span> map loop0p1 <span class=\"token punctuation\">(</span><span class=\"token number\">254</span>:0<span class=\"token punctuation\">)</span>: <span class=\"token number\">0</span> <span class=\"token number\">524289</span> linear <span class=\"token number\">7</span>:0 <span class=\"token number\">8192</span>\n<span class=\"token function\">add</span> map loop0p2 <span class=\"token punctuation\">(</span><span class=\"token number\">254</span>:1<span class=\"token punctuation\">)</span>: <span class=\"token number\">0</span> <span class=\"token number\">124194816</span> linear <span class=\"token number\">7</span>:0 <span class=\"token number\">540672</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Creamos dos directorios, uno para cada partición:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">mkdir</span> boot\n<span class=\"token function\">mkdir</span> rootfs</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Montamos ambas particiones utilizando los dos archivos de la salida anterior:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> <span class=\"token function\">mount</span> /dev/mapper/loop0p1 boot\n<span class=\"token function\">sudo</span> <span class=\"token function\">mount</span> /dev/mapper/loop0p2 rootfs</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Una vez hecho esto ya podemos leer o modificar cualquier archivo en nuestra imagen\nen los directorios boot y rootfs."), "\n", React.createElement(_components.p, null, "Una vez terminemos de trabajar deberemos desmontar las particiones y los archivos\nde mapeo."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> <span class=\"token function\">umount</span> boot rootfs\n<span class=\"token function\">sudo</span> kpartx <span class=\"token parameter variable\">-d</span> <span class=\"token parameter variable\">-v</span> rpi-armhf.img</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Y con esto nos queda nuestra imagen modificada que podemos grabar en cualquier\ntarjeta SD para disponer de un nuevo sistema."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
